// fetchDrmLicense.js
'use strict';
import fetch from '../resource/customFetch.js';
import getMeData from '../selector/getMeData.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import { getLicenseSignature } from '../resource/getDrmInfo.js';
import getPlayReadyChallenge from '../resource/getPlayReadyChallenge.js';
import { getSentry } from '../resource/sentry.js';
import { COM_MICROSOFT_PLAYREADY } from '../resource/drmConstants.js';
import {
  ADD_MESSAGE,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Fetch drm license
 * @kind action
 * @param {string} {keySystem} - key system id.
 * @param {Uint8Array} {requestLicenseBody} - body for request license.
 * @param {string} {messageId} - message id.
 * @param {function} {callback} - attach key to player when fetch success.
 * @return {Promise} Action promise.
 */
const fetchDrmLicense =
  ({ keySystem, requestLicenseBody, messageId, callback, kId }) =>
  async (dispatch, getState) => {
    if (!kId) return dispatch({ type: '' });

    const selectPath = ['drm-license', messageId];
    const token = getMeData(getState(), 'token');

    const fetchOptions = {
      method: 'POST',
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/octet-stream',
        Accept: 'application/octet-stream',
      },
      body: requestLicenseBody,
    };

    if (token) {
      fetchOptions.headers.Authorization = `Bearer ${token}`;
    }

    if (keySystem === COM_MICROSOFT_PLAYREADY) {
      const { xmlChallenge, headers } =
        getPlayReadyChallenge(requestLicenseBody);
      fetchOptions.body = xmlChallenge;
      fetchOptions.headers = {
        ...fetchOptions.headers,
        ...headers,
      };
      requestLicenseBody = xmlChallenge;
    }

    const signature = await getLicenseSignature({
      body: requestLicenseBody,
      token,
    });

    const url = getResourceUrl({ endpoint: `/drm/authorize/${keySystem}` });
    url.searchParams.append('kid', kId);
    url.searchParams.append('sig', signature);

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const license = await response.arrayBuffer();
      callback(null, license);
      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath },
      });
    } catch (error) {
      const { withScope, captureException } = await getSentry();
      if (withScope && captureException) {
        withScope(scope => {
          scope.setFingerprint(['drm', 'license']);
          scope.setTag('record', 'drm');
          captureException(error);
        });
      }
      let errorStatus = null;
      try {
        errorStatus = JSON.parse(error.message).status;
        // eslint-disable-next-line no-empty
      } catch (_) {}

      if ([400, 500].includes(errorStatus)) {
        dispatch({
          type: ADD_MESSAGE,
          payload: {
            id: messageId,
            viewScope: 'sd',
          },
        });
      } else {
        if (errorStatus) {
          dispatch({
            type: ADD_MESSAGE,
            payload: {
              id: messageId,
              viewScope: 'preview',
            },
          });
        }
      }
      dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
      return callback(true);
    }
  };

export default fetchDrmLicense;
