// getDrmKeySystemAndRobustness.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';
import { createSelector } from '../resource/createCachedSelector.js';

import getOperationData from '../selector/getOperationData.js';
import getFeatureDrmRequirement from '../selector/getFeatureDrmRequirement.js';

import {
  drmRulesetTestResult as drmRulesetTestResultConstant,
  drmRulesetTestStatus as DrmTestStatus,
  DrmRobustnessPriority,
  COM_MICROSOFT_PLAYREADY,
  COM_MICROSOFT_PLAYREADY_HARDWARE,
  COM_MICROSOFT_PLAYREADY_RECOMMENDATION,
  COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000,
} from '../resource/drmConstants.js';
import {
  getKeySystem,
  getLowestPlayreadyHardwarePriority,
} from '../resource/getDrmInfo.js';

const defaultObject = {};

/**
 * Select supported drm robustness by feature name
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {DrmFeatureName} drmFeatureName - drm feature name.
 * @return {object} Return object include key system and robustness.
 */
export const getDrmKeySystemAndRobustness = createCachedSelector(
  state => getHighestSupportedDrmKeySystemAndRobustness(state),
  (state, drmFeatureName) => getFeatureDrmRequirement(state, drmFeatureName),
  (state, drmFeatureName) => getIsDrmRequirementMatched(state, drmFeatureName),
  (
    resultObject = defaultObject,
    drmRequirementObject = defaultObject,
    isDrmRequirementMatched
  ) => {
    if (!isDrmRequirementMatched) {
      const keySystem = getKeySystem();
      return {
        keySystem,
        robustness: drmRequirementObject[keySystem],
      };
    }
    const { keySystem, robustness } = resultObject;
    return {
      keySystem,
      robustness,
    };
  }
)((state, drmFeatureName) => `${drmFeatureName}`);

export default getDrmKeySystemAndRobustness;

/**
 * Get hightest supported drm key system and robustness
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @return {object|undefined} Return object include key system and robustness.
 */
export const getHighestSupportedDrmKeySystemAndRobustness = createSelector(
  state => getOperationData(state, ['drm'], 'testResult'),
  (resultObject = defaultObject) => {
    const supportedList = Object.keys(resultObject)
      .map(keySystem => {
        return Object.keys(resultObject[keySystem])
          .filter(
            robustness =>
              drmRulesetTestResultConstant.SUPPORTED ===
              resultObject[keySystem][robustness]
          )
          .map(robustness => ({
            keySystem,
            robustness,
            priority: DrmRobustnessPriority[keySystem][robustness],
          }));
      })
      .reduce((accumulator, item) => accumulator.concat(item), [])
      .sort((a, b) => {
        const { priority: aPriority } = a;
        const { priority: bPriority } = b;
        return bPriority - aPriority;
      });
    const result = supportedList[0];
    if (!result) {
      return;
    }
    return {
      keySystem: result.keySystem,
      robustness: result.robustness,
    };
  }
);

/**
 * Get is drm requirement matched by feature name
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {DrmFeatureName} drmFeatureName - drm feature name.
 * @return {boolean} Return true if matched feature drm requirement.
 */
export const getIsDrmRequirementMatched = createCachedSelector(
  state => getOperationData(state, ['drm'], 'testStatus'),
  state => getHighestSupportedDrmKeySystemAndRobustness(state),
  (state, drmFeatureName) => getFeatureDrmRequirement(state, drmFeatureName),
  (testStatus = null, resultObject, drmRequirementObject = defaultObject) => {
    if (testStatus !== DrmTestStatus.TESTED) {
      return null;
    }
    if (!resultObject) {
      return false;
    }
    const { keySystem, robustness } = resultObject;
    const isPlayready = [
      COM_MICROSOFT_PLAYREADY,
      COM_MICROSOFT_PLAYREADY_HARDWARE,
      COM_MICROSOFT_PLAYREADY_RECOMMENDATION,
      COM_MICROSOFT_PLAYREADY_RECOMMENDATION_3000,
    ].includes(keySystem);
    const priority = DrmRobustnessPriority[keySystem][robustness];
    const requirementRobustness =
      drmRequirementObject[isPlayready ? COM_MICROSOFT_PLAYREADY : keySystem];
    const requirementPriority =
      isPlayready && '3000' === requirementRobustness
        ? getLowestPlayreadyHardwarePriority()
        : DrmRobustnessPriority[keySystem][requirementRobustness];
    return requirementPriority == null || priority >= requirementPriority;
  }
)((state, drmFeatureName) => `${drmFeatureName}`);
