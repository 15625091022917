// setPlayerData.js
'use strict';
import { MERGE_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Set player operation data
 * @kind action
 * @param {Object} data
 * @param {boolean} [data.isMuted] - mute player
 * @param {boolean} [data.isFirstUnmuted] - mute player
 * @param {boolean} [data.hasFlixPlayed] - has flix played
 * @param {boolean} [data.isVideoControlDisplayed] - video control displayed.
 * @param {string} [data.controlDisplayedMessageId] - video control displayed.
 * @param {boolean} [data.isSeeking] - video control seeking.
 * @param {boolean} [data.isFullscreen] - is video fullscreen.
 * @return {Promise} Action promise.
 */
const setPlayerData =
  (data = {}) =>
  async dispatch => {
    const selectPath = ['player'];

    return dispatch({
      type: MERGE_OPERATION_DATA,
      payload: { selectPath, data },
    });
  };

export default setPlayerData;
