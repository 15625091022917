// getMessageDrmMediaSource.js
'use strict';
import { Collection } from 'immutable';

import { PREVIEW_PRESET } from '../RemoteConfigKeys.js';

import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import createCachedSelector from '../resource/createCachedSelector.js';
import {
  getAssetManifest,
  getAssetTrailer,
  MediaAssetFormat,
  MediaPreset,
} from '../resource/getMediaAsset.js';

/**
 * Select drm media source by
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} messageId - message id.
 * @param {string} assetId - message asset id.
 * @param {string} dataKey - data key.
 * @param {string} preset - trailer asset preset.
 * @return {any} The selected drm media source.
 */
const getMessageDrmMediaSource = createCachedSelector(
  (state, messageId) => messageId,
  (state, messageId) => state.getIn(['messages', messageId]),
  (state, messageId, assetId) => assetId,
  (state, messageId, assetId, dataKey) => dataKey,
  (state, messageId, assetId, dataKey, preset) => preset,
  state => getRemoteConfigData(state, PREVIEW_PRESET),
  (messageId, message, assetId, dataKey, preset, remoteConfigPreviewPreset) => {
    let result = undefined;
    if ('drmMediaSource' === dataKey) {
      const viewScope = message?.get('viewScope') || 'preview';
      const canViewMedia =
        viewScope === 'sd' && remoteConfigPreviewPreset === 'clear';
      const mp4Url = getAssetTrailer({
        id: messageId,
        assetId,
        format: MediaAssetFormat.MP4,
        preset:
          (preset
            ? preset
            : canViewMedia
              ? 'clear'
              : remoteConfigPreviewPreset) === 'clear'
            ? MediaPreset.SD
            : MediaPreset.SD_PREVIEW,
      }).href;
      result = {
        dash: getAssetManifest({
          id: messageId,
          assetId,
          format: MediaAssetFormat.MPD,
          preset: canViewMedia ? MediaPreset.SD : MediaPreset.SD_PREVIEW,
        }).href,
        hls: getAssetManifest({
          id: messageId,
          assetId,
          format: MediaAssetFormat.M3U8,
          preset: canViewMedia ? MediaPreset.SD : MediaPreset.SD_PREVIEW,
        }).href,
        mp4: mp4Url,
      };
    }
    return result instanceof Collection ? result.toJS() : result;
  }
)(
  (state, messageId, dataKey, preset) =>
    `getMessageDrmMediaSource:${messageId}:${dataKey}:${preset}`
);

export default getMessageDrmMediaSource;
