// MessageVideoPlayer.jsx
import React from 'react';
import PropTypes from 'prop-types';

import DecryptionWrapper from '../container/DecryptionWrapper.js';
import ShakaPlayer from './ShakaPlayer.jsx';
import { getKeySystem } from '../resource/getDrmInfo.js';
import { drmRulesetTestStatus as DrmTestStatus } from '../resource/drmConstants.js';
// import getPublicSrcSet from '../resource/getPublicSrcSet.js';
import getImageSupport from '../resource/getImageSupport.js';

/** @typedef {import('video.js').VideoJsPlayer} VideoJsPlayer */

/**
 * @typedef {Object} Props
 * @property {string} token
 * @property {string} messageId
 * @property {Object} source
 * @property {string} mediaType
 * @property {'preview' | 'sd' | 'hd'} viewScope
 * @property {boolean} canViewMedia
 * @property {boolean} isMuted
 * @property {(arg: { id: string }) => Promise<void>} fetchDrmLicenseAvailability
 *
 * @extends {React.PureComponent<Props>}
 */
class MessageVideoPlayer extends React.PureComponent {
  /** @type {VideoJsPlayer?}*/
  player = null;
  posterFormat = getImageSupport().bestFormat;

  componentDidMount() {
    const { messageId, canViewMedia, fetchDrmLicenseAvailability } = this.props;
    if (messageId && canViewMedia === null) {
      fetchDrmLicenseAvailability({
        keySystem: getKeySystem(),
        messageId,
      });
    }
    this.fetchMediaMenifestOfMessage();

    this.checkDrmCapabilityBeforePlay();
    this.setPlayerThumbnail();
  }

  componentDidUpdate(prevProps) {
    const {
      meId,
      messageId,
      token,
      drmTestStatus,
      canViewMedia,
      fetchDrmLicenseAvailability,
    } = this.props;
    if (
      messageId !== prevProps.messageId ||
      meId !== prevProps.meId ||
      canViewMedia !== prevProps.canViewMedia
    ) {
      if (messageId && canViewMedia === null) {
        fetchDrmLicenseAvailability({
          keySystem: getKeySystem(),
          messageId,
        });
      }

      this.fetchMediaMenifestOfMessage();
    }
    if (canViewMedia !== prevProps.canViewMedia || token !== prevProps.token) {
      this.setPlayerThumbnail();
    }
    if (
      canViewMedia !== prevProps.canViewMedia ||
      token !== prevProps.token ||
      drmTestStatus !== prevProps.drmTestStatus
    ) {
      this.checkDrmCapabilityBeforePlay();
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player = undefined;
      delete this.player;
    }
  }

  fetchMediaMenifestOfMessage = () => {
    const { messageId, assetId, fetchMediaMenifestOfMessage } = this.props;
    // Post (has assetId) get mediaType from content-type
    if (!messageId || assetId) {
      return;
    }
    return fetchMediaMenifestOfMessage({ id: messageId });
  };

  setPlayerThumbnail = () => {
    // 2023.02.22
    // Temporarily cut out the poster to avoid the video out of shape on Safari,
    // especially for Stories and Feeds.
    //
    // const { thumbnail } = this.props;
    // const srcSet = getPublicSrcSet({
    //   href: thumbnail,
    //   size: 512,
    //   format: this.posterFormat,
    // });
    // this?.player?.poster(srcSet.src);
  };

  checkDrmCapabilityBeforePlay = () => {
    const {
      drmFeatureName,
      drmTestStatus,
      canViewMedia,
      token,
      checkDrmCapabilityBeforePlay,
    } = this.props;
    // check token to prevent check drm for SEO crawlers.
    // player will just play with drm level from remote config.
    if (canViewMedia && token && DrmTestStatus.TESTING !== drmTestStatus) {
      return checkDrmCapabilityBeforePlay({ drmFeatureName });
    }
    return;
  };

  render() {
    const {
      token,
      viewScope,
      mediaType,
      keySystem,
      robustness,
      drmSource,
      isMuted,
      canViewMedia,
      fetchDrmLicense,
      fetchPlayReadyLicense,
      splashVideo,
      ...otherProps
    } = this.props;

    return (
      <DecryptionWrapper
        resourceUrl={drmSource?.mp4}
        splashUrl={splashVideo?.dash}
      >
        <ShakaPlayer
          isMuted={isMuted}
          canViewMedia={canViewMedia}
          token={token}
          source={drmSource}
          splashVideo={splashVideo}
          viewScope={viewScope}
          mediaType={mediaType}
          keySystem={keySystem}
          robustness={robustness}
          fetchDrmLicense={fetchDrmLicense}
          fetchPlayReadyLicense={fetchPlayReadyLicense}
          decryptionType="video/mp4"
          {...otherProps}
        />
      </DecryptionWrapper>
    );
  }
}

MessageVideoPlayer.propTypes = {
  meId: PropTypes.string,
  token: PropTypes.string,
  messageId: PropTypes.string,
  assetId: PropTypes.string,
  drmFeatureName: PropTypes.string,
  drmSource: PropTypes.object,
  splashVideo: PropTypes.object,
  canViewMedia: PropTypes.bool,
  isMuted: PropTypes.bool,
  isFlix: PropTypes.bool,
  viewScope: PropTypes.string,
  mediaType: PropTypes.string,
  keySystem: PropTypes.string,
  robustness: PropTypes.string,
  drmTestStatus: PropTypes.string,
  thumbnail: PropTypes.string,
  widevineServerCertificateBase64: PropTypes.string,
  fetchDrmLicenseAvailability: PropTypes.func,
  fetchPlayReadyLicense: PropTypes.func,
  fetchDrmLicense: PropTypes.func,
  checkDrmCapabilityBeforePlay: PropTypes.func,
  fetchMediaMenifestOfMessage: PropTypes.func,
};

MessageVideoPlayer.defaultProps = {
  meId: '',
  token: '',
  messageId: '',
  assetId: '',
  drmFeatureName: null,
  drmSource: {},
  splashVideo: {},
  canViewMedia: null,
  isMuted: true,
  isFlix: false,
  viewScope: 'preview',
  mediaType: 'image',
  keySystem: null,
  robustness: null,
  drmTestStatus: null,
  thumbnail: null,
  widevineServerCertificateBase64: '',
  fetchDrmLicenseAvailability: () => null,
  fetchDrmLicense: () => null,
  checkDrmCapabilityBeforePlay: () => null,
  fetchPlayReadyLicense: () => null,
  fetchMediaMenifestOfMessage: () => null,
};

export default MessageVideoPlayer;
