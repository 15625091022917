// fetchMediaMenifestOfMessage.js
// TODO: change menifest to manifest
'use strict';
import getMessageData from '../selector/getMessageData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import fetchMediaMenifest from '../resource/fetchMediaMenifest.js';
import {
  ADD_MESSAGE,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

/**
 * Fetch media menifest of message
 * @kind action
 * @param {string} {id} - action param.
 * @return {Promise} Action promise.
 */
const fetchMediaMenifestOfMessage =
  ({ id }) =>
  async (dispatch, getState) => {
    const selectPath = ['messages', id, 'media', 'menifest'];
    const streamType = 'mpd';
    const isFetched = getNetworkingData(getState(), selectPath, 'isFetched');
    const isFetching = getNetworkingData(getState(), selectPath, 'isFetching');
    const error = getNetworkingData(getState(), selectPath, 'error');
    if (isFetching || (isFetched && !error)) {
      return dispatch({ type: '' });
    }
    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    let menifestType = undefined;
    let menifest = {};
    try {
      menifest = await fetchMediaMenifest({
        id,
        assetId: getMessageData(getState(), id, 'assetIds')?.[0],
        type: streamType,
      });
      if (!menifest) {
        throw new Error('parse_menifest_error');
      }
      menifestType = streamType;
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { error, selectPath },
      });
    }
    const mediaType = menifest[menifestType]?.mediaType || null;
    let duration = null;
    duration = menifest[menifestType].duration;
    const media = duration
      ? { type: mediaType, duration }
      : { type: mediaType };
    dispatch({
      type: ADD_MESSAGE,
      payload: {
        id,
        media,
        menifestType,
        menifest,
      },
    });
    return dispatch({ type: SET_NETWORKING_SUCCESS, payload: { selectPath } });
  };

export default fetchMediaMenifestOfMessage;
