// checkDrmCapabilityBeforePlay.js
'use strict';
import toggleDrmNotSupportAlert from '../action/toggleDrmNotSupportAlert.js';

import { getIsDrmRequirementMatched } from '../selector/getDrmKeySystemAndRobustness.js';
import getOperationData from '../selector/getOperationData.js';

import { drmRulesetTestStatus as DrmTestStatus } from '../resource/drmConstants.js';

/**
 * Check drm capability before play
 * @kind action
 * @param {string} {drmFeatureName} - drm feature name.
 * @return {Promise} Action promise.
 */
const checkDrmCapabilityBeforePlay =
  ({ drmFeatureName } = {}) =>
  async (dispatch, getState) => {
    const testStatus = getOperationData(getState(), ['drm'], 'testStatus');
    const isTesting = DrmTestStatus.TESTING === testStatus;
    if (isTesting) {
      return dispatch({ type: '' });
    }
    const checkDrmCapability = (await import('../action/checkDrmCapability.js'))
      .default;
    await dispatch(checkDrmCapability({ isEarlyLeave: true }));
    const isMatched = getIsDrmRequirementMatched(getState(), drmFeatureName);
    if (isMatched === false) {
      return dispatch(toggleDrmNotSupportAlert());
    }
  };

export default checkDrmCapabilityBeforePlay;
