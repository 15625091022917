// MessageVideoPlayer.js
import { connect } from 'react-redux';

import getMeData from '../selector/getMeData.js';
import getMessageData from '../selector/getMessageData.js';
import getMessageDrmMediaSource from '../selector/getMessageDrmMediaSource.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getMessageThumbnail from '../selector/getMessageThumbnail.js';
import getDrmKeySystemAndRobustness from '../selector/getDrmKeySystemAndRobustness.js';
import getOperationData from '../selector/getOperationData.js';
import fetchDrmLicenseAvailability from '../action/fetchDrmLicenseAvailability.js';
import fetchDrmLicense from '../action/fetchDrmLicense.js';
import MessageVideoPlayer from '../component/MessageVideoPlayer.jsx';
import checkDrmCapabilityBeforePlay from '../action/checkDrmCapabilityBeforePlay.js';
import fetchMediaMenifestOfMessage from '../action/fetchMediaMenifestOfMessage.js';
import toggleDrmNotSupportAlert from '../action/toggleDrmNotSupportAlert.js';
import setPlayerData from '../action/setPlayerData.js';
import {
  DRM_LICENSE_DURATION,
  WIDEVINE_SERVER_CERTIFICATE_BASE64,
} from '../RemoteConfigKeys.js';
import getAssetData from '../selector/getAssetData.js';
import { getIsOnMobile } from '../resource/getUserAgent.js';
import {
  COM_WIDEVINE_ALPHA,
  ORG_W3_CLEARKEY,
  DrmFeatureName,
} from '../resource/drmConstants.js';

const isOnMobile = getIsOnMobile();

const mapStateToProps = (state, { messageId, assetId, drmFeatureName }) => {
  const token = getMeData(state, 'token');
  const isFlix = getMessageData(state, messageId, 'isFlix');
  const { keySystem, robustness } = getDrmKeySystemAndRobustness(
    state,
    drmFeatureName
  );
  const shouldForceClearkey =
    isOnMobile &&
    drmFeatureName === DrmFeatureName.SHORT &&
    keySystem === COM_WIDEVINE_ALPHA;
  const canViewMedia = getMessageData(state, messageId, 'canViewMedia');
  return {
    meId: getMeData(state, 'id'),
    clientId: getMeData(state, 'clientId'),
    token,
    keySystem: shouldForceClearkey ? ORG_W3_CLEARKEY : keySystem,
    robustness: shouldForceClearkey ? 'SW_SECURE_CRYPTO' : robustness,
    drmSource: getMessageDrmMediaSource(
      state,
      messageId,
      assetId,
      'drmMediaSource'
    ),
    canViewMedia,
    isDrmLicenseAvailabilityFetched: getNetworkingData(
      state,
      ['drm', 'licenseAvailability', messageId],
      'isFetched'
    ),
    isFlix,
    viewScope: getMessageData(state, messageId, 'viewScope'),
    mediaType: assetId
      ? getAssetData(state, [assetId], 'contentType')
      : getMessageData(state, messageId, 'mediaType'),
    splashVideo: getRemoteConfigData(state, 'splashVideo'),
    drmLicenseDuration: getRemoteConfigData(state, DRM_LICENSE_DURATION),
    drmTestStatus: getOperationData(state, ['drm'], 'testStatus'),
    thumbnail: getMessageThumbnail(
      state,
      messageId,
      assetId,
      '512x512',
      'jpg',
      canViewMedia ? 'clear' : 'blurred'
    ),
    widevineServerCertificateBase64: getRemoteConfigData(
      state,
      WIDEVINE_SERVER_CERTIFICATE_BASE64
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPlayerData: ({ isMuted }) =>
      dispatch(setPlayerData({ isMuted, isFirstUnmuted: false })),
    fetchDrmLicenseAvailability: data =>
      dispatch(fetchDrmLicenseAvailability(data)),
    fetchDrmLicense: data => dispatch(fetchDrmLicense(data)),
    // Directly use the DrmNotSupport alert since the original wording is neutral
    openPlaybackNotSupportAlert: () => dispatch(toggleDrmNotSupportAlert()),
    checkDrmCapabilityBeforePlay: ({ drmFeatureName }) =>
      dispatch(checkDrmCapabilityBeforePlay({ drmFeatureName })),
    fetchMediaMenifestOfMessage: ({ id }) =>
      dispatch(fetchMediaMenifestOfMessage({ id })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageVideoPlayer);
