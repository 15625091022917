// getImageSupport.js
'use strict';
import { MediaAssetFormat } from '../resource/getMediaAsset.js';

const isServer = typeof window === 'undefined';

export const WebpFeature = {
  LOSSY: 'lossy',
  LOSSLESS: 'lossless',
  ALPHA: 'alpha',
  ANIMATION: 'animation',
};
const webpType = 'data:image/webp;base64,';
const WebpFeatureAssets = {
  [WebpFeature.LOSSY]:
    webpType + 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
  [WebpFeature.LOSSLESS]:
    webpType + 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
  [WebpFeature.ALPHA]:
    webpType +
    'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
  [WebpFeature.ANIMATION]:
    webpType +
    'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
};
// ref: https://stackoverflow.com/a/71815258
const AvifAsset =
  'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';

const supportResult = {
  [MediaAssetFormat.JPG]: true,
  [MediaAssetFormat.WEBP]: false,
  [MediaAssetFormat.AVIF]: false,
};
let bestFormat = MediaAssetFormat.JPG;
let isChecked = false;
let isChecking = false;

// ref: https://stackoverflow.com/a/54631141
const checkAssetSupport = (source, callback) => {
  const img = new Image();
  img.onload = () => {
    const result = img.width > 0 && img.height > 0;
    callback(result);
  };
  img.onerror = () => {
    callback(false);
  };
  img.src = source;
};

const checkAssetsSupport = async () => {
  const results = await Promise.all(
    [
      WebpFeatureAssets[WebpFeature.LOSSY],
      WebpFeatureAssets[WebpFeature.LOSSLESS],
      WebpFeatureAssets[WebpFeature.ALPHA],
      WebpFeatureAssets[WebpFeature.ANIMATION],
      AvifAsset,
    ].map(source => {
      return new Promise(resolve => {
        checkAssetSupport(source, isSupported => resolve({ isSupported }));
      });
    })
  );
  supportResult[MediaAssetFormat.WEBP] = {
    [WebpFeature.LOSSY]: !!results[0]?.isSupported,
    [WebpFeature.LOSSLESS]: !!results[1]?.isSupported,
    [WebpFeature.ALPHA]: !!results[2]?.isSupported,
    [WebpFeature.ANIMATION]: !!results[3]?.isSupported,
  };

  supportResult[MediaAssetFormat.AVIF] = !!results[4]?.isSupported;

  bestFormat = supportResult[MediaAssetFormat.AVIF]
    ? MediaAssetFormat.AVIF
    : supportResult[MediaAssetFormat.WEBP]?.[WebpFeature.LOSSY]
      ? MediaAssetFormat.WEBP
      : MediaAssetFormat.JPG;

  isChecked = true;
  isChecking = false;
};

/**
 * Get image support
 */
const getImageSupport = () => {
  if (!isChecked && !isChecking && !isServer) {
    isChecking = true;
    setTimeout(checkAssetsSupport);
  }
  return { supportResult, isChecked, bestFormat };
};

export default getImageSupport;
