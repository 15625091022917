// getPlayReadyChallenge.js
'use strict';

/**
 * Parses the EME key message XML to extract HTTP headers and the Challenge element to use
 * in the PlayReady license request.
 *
 * @param {ArrayBuffer} challenge - key challenge from EME
 * @return {Object} an object containing headers and the message body to use in the
 * license request
 */
const getPlayReadyChallenge = challenge => {
  const xml = new window.DOMParser().parseFromString(
    // TODO do we want to support UTF-8?
    String.fromCharCode.apply(null, new Uint16Array(challenge)),
    'application/xml'
  );
  const headersElement = xml.getElementsByTagName('HttpHeaders')[0];
  const headers = {};

  if (headersElement) {
    const headerNames = headersElement.getElementsByTagName('name');
    const headerValues = headersElement.getElementsByTagName('value');

    for (let i = 0; i < headerNames.length; i++) {
      headers[headerNames[i].childNodes[0].nodeValue] =
        headerValues[i].childNodes[0].nodeValue;
    }
  }

  const challengeElement = xml.getElementsByTagName('Challenge')[0];
  let xmlChallenge;
  if (challengeElement) {
    xmlChallenge = window.atob(challengeElement.childNodes[0].nodeValue);
  }

  return {
    headers,
    xmlChallenge,
  };
};

export default getPlayReadyChallenge;
