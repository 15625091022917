// getFeatureDrmRequirement.js
'use strict';
import createCachedSelector from '../resource/createCachedSelector.js';

import {
  DRM_REQUIREMENT_FEATURE_FLIX,
  DRM_REQUIREMENT_FEATURE_STORY,
  DRM_REQUIREMENT_FEATURE_CHAT,
  DRM_REQUIREMENT_FEATURE_LIVESTREAM,
  DRM_REQUIREMENT_FEATURE_POST,
} from '../RemoteConfigKeys.js';

import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import { DrmFeatureName } from '../resource/drmConstants.js';

const FEATURE_NAME_CONFIG_KEY_MAP = {
  [DrmFeatureName.FLIX]: DRM_REQUIREMENT_FEATURE_FLIX,
  [DrmFeatureName.STORY]: DRM_REQUIREMENT_FEATURE_STORY,
  [DrmFeatureName.CHAT]: DRM_REQUIREMENT_FEATURE_CHAT,
  [DrmFeatureName.LIVESTREAM]: DRM_REQUIREMENT_FEATURE_LIVESTREAM,
  [DrmFeatureName.POST]: DRM_REQUIREMENT_FEATURE_POST,
};

/**
 * Select feature drm requirement
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} drmFeatureName - drm feature name.
 * @return {object|undefined} Return key system's drm robustness.
 */
const getFeatureDrmRequirement = createCachedSelector(
  (state, drmFeatureName) =>
    getRemoteConfigData(state, FEATURE_NAME_CONFIG_KEY_MAP[drmFeatureName]),
  (drmRequirementString = '') => {
    if (!drmRequirementString) {
      return;
    }
    return drmRequirementString.split(' ').reduce((accumulator, item) => {
      const [keySystem, robustness] = item.split(':');
      accumulator[keySystem] = robustness;
      return accumulator;
    }, {});
  }
)((state, drmFeatureName) => `${drmFeatureName}`);

export default getFeatureDrmRequirement;
