// toggleDrmNotSupportAlert.js
'use strict';
import { REMOVE_OPERATION_DATA, SET_OPERATION_DATA } from '../ActionTypes.js';
import addModal from '../action/addModal.js';
import removeModals from '../action/removeModals.js';
import getModalData from '../selector/getModalData.js';
import mergeMeData from '../action/mergeMeData.js';
import getMeData from '../selector/getMeData.js';

export const MODAL_ID = 'DrmNotSupportAlert';

const INTERVAL_TO_SHOW_NEXT_ALERT = 24 * 60 * 60 * 1000;

/**
 * Toggle drm not support alert
 * @kind action
 * @param {string} {actionName} - original action name.
 * @param {object} {actionPayload} - original action payload.
 * @return {Promise} Action promise.
 */
const toggleDrmNotSupportAlert =
  ({ actionName, actionPayload } = {}) =>
  async (dispatch, getState) => {
    const isModalOpened =
      getModalData(getState(), MODAL_ID, 'status') === 'display';
    if (isModalOpened) {
      dispatch({
        type: REMOVE_OPERATION_DATA,
        payload: {
          selectPath: ['modal', MODAL_ID],
        },
      });
      return dispatch(
        removeModals({
          ids: [MODAL_ID],
          transitionStatus: 'closing',
        })
      );
    }

    const now = Date.now();
    const lastNotSupportAlertTimestamp = getMeData(
      getState(),
      'lastNotSupportAlertTimestamp'
    );
    if (now - lastNotSupportAlertTimestamp < INTERVAL_TO_SHOW_NEXT_ALERT) {
      return { type: '' };
    }
    dispatch(
      mergeMeData({ field: 'lastNotSupportAlertTimestamp', value: now })
    );

    dispatch({
      type: SET_OPERATION_DATA,
      payload: {
        selectPath: ['modal', MODAL_ID],
        data: {
          actionName,
          actionPayload,
        },
      },
    });
    return dispatch(
      addModal({
        id: MODAL_ID,
        transitionStatus: 'opening',
        isHigherThanAll: true,
      })
    );
  };

export default toggleDrmNotSupportAlert;
